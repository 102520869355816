/* eslint-disable no-unused-vars */

const SectionHeaderView = require('../../common/marionette/header/section/views/SectionHeaderView.js');
const SectionHeaderModel = require('../../common/marionette/header/section/models/SectionHeader.js');
const StripeService = require('../../services/StripeService');
const FeatureFlagModel = require('../../models/FeatureFlag');
const FeatureService = require('../../services/FeatureService');
const FiscalYearService = require('../../services/FiscalYearService');

window.stripeService = new StripeService();

const userInfoRequest = Wethod.request('user:get:info');
const sentencesDaysRequest = Wethod.request('get:sentences', { context: 'login', labels: moment().format('dddd').toLowerCase() });
const sentencesSongRequest = Wethod.request('get:sentences', { context: 'login', labels: 'song' });

$.when(
  userInfoRequest,
  sentencesDaysRequest,
  sentencesSongRequest,
  FeatureFlagModel.getEnabled(),
).done((userInfo,
  sentencesDaysResponse, sentencesSongResponse, enabledFeatures) => {
  Wethod.userInfo = userInfo;
  Wethod.sentencesDays = sentencesDaysResponse;
  Wethod.sentencesSongs = sentencesSongResponse;
  Wethod.featureService = new FeatureService(enabledFeatures['feature-flags']);
  Wethod.fiscalYearService = new FiscalYearService();
  Wethod.start();
});
